import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IMAGE_CONSTANTS } from '../../../shared/imageConstants';
import { Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import { StudentDashboardService } from '../../../modules/student/student-dashboard/student-dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'cf-report-second-section',
  templateUrl: './report-second-section.component.html',
  styleUrls: ['./report-second-section.component.scss']
})
export class ReportSecondSectionComponent implements OnInit {
  IMAGES: any = IMAGE_CONSTANTS;
  paymentdata: any;
  description: string
  htmlContent: string;
  // booksData: any = [
  //   { title: 'Title 1', subTitle: 'Sub title for book 1', price: '506' },
  //   { title: 'Title 2', subTitle: 'Sub title for book 2', price: '200' },
  //   { title: 'Title 3', subTitle: 'Sub title for book 3', price: '900' },
  //   { title: 'Title 4', subTitle: 'Sub title for book 4', price: '1000' },
  // ];
  // videosData: any = [
  //   { time: '15:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //   { time: '1:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //   { time: '5:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  //   { time: '9:30:06', subTitle: 'Cosmetic dentistry Course in India' },
  // ];
  showShare: boolean;
  showNotes: boolean;
  booksData: any ;
  videosData: any ;
  reportsData: any = [
    {
      title: 'Get to Know About',
      subTitle: 'My Carrer Enjoyment Report',
      description: 'You should buy Career Enjoyment report',
    },
    {
      title: 'Get to Know About',
      subTitle: 'My Carrer Enjoyment Report',
      description: 'You should buy Career Enjoyment report',
    },
    {
      title: 'Get to Know About',
      subTitle: 'My Carrer Enjoyment Report',
      description: 'You should buy Career Enjoyment report',
    },
  ];


  close(status){
    this.showNotes = false;
  }
  cancelPopup(event) {
    this.showShare = false;
  }
  reports: any =[
    {
      title: 'Get to know About',
      subTitle: 'My Career Options Report',
      careerTwo: '',
      description: 'You should buy Career Options reports',
      reportTwo: ' ',
      key: 'Co1',
      isSelected: false,
      isPurchased:false,
      routingPath: '',
      background:'../../../../assets/getToKnow.svg',
      color:''
    },
    {
      title: 'Get to know About',
      subTitle: 'My Greatest Strengths Report',
      careerTwo: '',
      description: 'You should buy Greatest Strengths reports',
      reportTwo: '',
      key: 'Ygs',
      isSelected: false,
      isPurchased:false,
      routingPath: '',
      background:'../../../../assets/getToKnow.svg'
    },
    {
      title: 'Get to know About',
      subTitle: 'My Personality Report',
      careerTwo: '',
      description: 'You should buy Personality reports',
      reportTwo: '',
      key: 'Cd',
      isSelected: false,
      isPurchased:false,
      routingPath: '',
      background:'../../../../assets/getToKnow.svg'
    },
    {
      title: 'Get to know About',
      subTitle: 'My Traits and Definitions Report',
      careerTwo: '',
      description: 'You should buy Traits and Definitions report',
      reportTwo: ' ',
      key: 'Td',
      isSelected: false,
      isPurchased:false,
      routingPath: '',
      background:'../../../../assets/getToKnow.svg'
    },
    {
      title: 'Get to know About',
      subTitle: 'My Career Enjoyment Report',
      careerTwo: '',
      description: 'You should buy Career Enjoyment reports',
      reportTwo: '',
      key: 'Cc_', 
      isSelected: false,
      isPurchased:false,
      routingPath: '/careersList',
      background:'../../../../assets/getToKnow.svg'
    },
  ]
  constructor(private storage: StorageService,private router: Router,private dashboard: StudentDashboardService ,private modalService: NgbModal) {}
  ngOnInit(): void {
    let reportexist=this.storage.getItem('userData').reportExists;
    for (const index in this.reports) {
      for (const rindex in reportexist) {
        console.log(this.reports[index].key)
        console.log(index)
        console.log(rindex)
        if(this.reports[index].key===rindex && reportexist[rindex]===true)
        {
          this.reports[index].isPurchased=true;
        }
      }
    }

  }
  closeModal() { this.modalService.dismissAll(); }


  paymentprocess(payment,reportname,reportdescription)
  {
    console.log(reportname,payment);


    reportname='Cc_'+reportname
    // this.reportName = reportname
    let userData= this.storage.getItem('userData');
    this.description = reportdescription;
    let data={
      productinfo:reportname,
      studentId:userData.student_id,
      // amount:payment

    }
    this.dashboard.getHashForPayment(data).subscribe((res: any) => {      
      this.paymentdata=JSON.parse(res);
      this.modalService.dismissAll();
      this.htmlContent=res;
      console.log(payment);
      this.modalService.open(payment);     
    });
  }

  // paymentprocess(payment, reportname) {
  //   let userData = this.storage.getItem('userData');
  //   let data = {
  //     productinfo: reportname,
  //     studentId: userData.student_id,
  //   };
  //   console.log('came 1')
  //   this.dashboard.getHashForPayment(data).subscribe((res: any) => {
  //     this.paymentdata = JSON.parse(res);
  //     this.modalService.dismissAll();
  //     this.htmlContent = res;
  //     console.log('came 3',payment,res,this.htmlContent)
  //     this.modalService.open(payment);
  //   });
  // }

  // onSubmit(e: any){
  //   e.target.submit();
  // }
}
