import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { AuthServiceService } from "@app/services/authService.service";
import {PipesModule} from '@app/modules/pipes.module';
import { AppRoutingModule } from './app-routing.module';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { ShortlistedCareersComponent } from './modules/student/shortlisted-careers/shortlisted-careers.component';
import { CareerOptionsComponent } from './modules/student/career-options/career-options.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { Ng9OdometerModule } from 'ng9-odometer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { ColorPickerModule } from 'ngx-color-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideDirective } from "@app/directive/outsideClick.directive";
import { DirectiveModule } from "@app/directive/directive.module";
import { CourseListModule } from './modules/student/student-cources/cources-list/course-list.module';
@NgModule({
  declarations: [AppComponent, ShortlistedCareersComponent, CareerOptionsComponent,ClickOutsideDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    CourseListModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    NgxSpinnerModule,
    Ng9OdometerModule.forRoot(),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    // NgbPaginationModule,
    PipesModule,
    ColorPickerModule,
    DirectiveModule,
    NgbAccordionModule
  ],
  exports:[PipesModule],
  providers: [AuthServiceService],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]


})
export class AppModule {}
