<div class="careerEnjoymentright">
  <cf-reports-books></cf-reports-books>
  <ngb-carousel class="sliderCarousal">
    <ng-container *ngFor="let item of reports;index as i;">
      <ng-template ngbSlide *ngIf="item.isPurchased===false">
        <div class='careersData container'>
          <div class="row">
            <!-- <div class="col-1"></div> -->
          <div class="reportbox col-12" >
            <div [id]="'report'+(item.key)">
              <div class="container reportbox__report " >
                <h1> {{item.title}}</h1>
                <h2> {{item.subTitle}}</h2>
                <p> {{item.description}}</p>
                <div class="reportbox__report__buttonText">
                  <button *ngIf="item.key!=='Cc_'" (click)="paymentprocess(payment,item.key,item.subTitle)" class="btn"> Buy Now</button>
                  <button *ngIf="item.key==='Cc_'" class="btn"><a href="/careersList"> Buy Now</a></button>
                </div>
              </div>
            </div>
            </div>
            <!-- <div class="col-1"></div> -->
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ngb-carousel>
  <cf-report-videos></cf-report-videos>
  <div class="share">
    <p><img  src="{{ IMAGES.FEED_BACK }}"  class="feedbackIcon" alt="share" (click)="showShare=!showShare"/></p>
    <p><img  src="{{ IMAGES.NOTE_SELECTED }}" alt="share" (click)="showNotes=!showNotes" class="cursor"/></p>
  </div>
<cf-notes (onClose)="close($event)" *ngIf="showNotes"></cf-notes>
<!-- <cf-share *ngIf="showShare"></cf-share> (cancelOutput) ="cancelPopup($event)"-->
<cf-feedback *ngIf="showShare"(cancelOutput) ="cancelPopup($event)" ></cf-feedback>
  
</div>
<ng-template #payment let-modal >
  <div class="headerSection">
<div class="payment"><form #form action="https://test.payu.in/_payment" id="payment_form" method="Post">
  <div class="cartHeading">Order Confirmation</div>
	<input type="hidden" id="udf5" name="udf5" value="{{paymentdata.udf5}}" />    
	<input type="hidden" id="surl" name="surl" value="{{paymentdata.surl}}" />
	<input type="hidden" id="furl" name="furl" value="{{paymentdata.furl}}" />
  <input type="hidden" id="curl" name="curl" value="{{paymentdata.curl}}" />
  <input type="hidden" id="key" name="key" value="{{paymentdata.key}}" />  
  <!-- <div><span>Order ID&nbsp;:</span><span>&nbsp;{{paymentdata.txnid}}</span></div> 
  <div><span>Amount&nbsp;  :</span><span>&nbsp;{{paymentdata.amount}}</span></div>  -->
  <div class="cartItemHeading">
    <div class="reportname">{{description}}</div> 
    <div class="amount">{{paymentdata.amount}}</div>
  </div>
  <input type="hidden" id="txnid" name="txnid" placeholder="Transaction ID" value="{{paymentdata.txnid}}" />
  <input type="hidden" id="amount" name="amount" placeholder="Amount" value="{{paymentdata.amount}}" />
  <input type="hidden" id="productinfo" name="productinfo" placeholder="Product Info" value="{{paymentdata.productinfo}}" />
  <input type="hidden" id="firstname" name="firstname" placeholder="First Name" value="{{paymentdata.firstname}}" />
	<input type="hidden" id="Lastname" name="Lastname" placeholder="Last Name" value="" />
	<input type="hidden" id="email" name="email" placeholder="Email ID" value="{{paymentdata.email}}" />
  <input type="hidden" id="phone" name="phone" placeholder="Mobile/Cell Number" value="{{paymentdata.phone}}" />
  <input type="hidden" id="address1" name="address1" placeholder="Address1" value="" />
  <input type="hidden" id="address2" name="address2" placeholder="Address2" value="" />
  <input type="hidden" id="city" name="city" placeholder="City" value="" />
  <input type="hidden" id="state" name="state" placeholder="State" value="" />
  <input type="hidden" id="country" name="country" placeholder="Country" value="" />
	<input type="hidden" id="Zipcode" name="Zipcode" placeholder="Zip Code" value="" />
	<input type="hidden" id="Pg" name="Pg" placeholder="PG" value="CC" />
	<input type="hidden" id="hash" name="hash" placeholder="Hash" value="{{paymentdata.hash}}" />
  <div id="alertinfo" class="dv"></div>
  <div class="payment-head">
    <div>
      <input type="button" value="Cancel" class="button-cnl"  (click)="closeModal()"/>
      <input type="submit" value="Confirm" class="btn checkoutButton" (click)="form.submit()"/>
    </div>
  </div>
	</form></div>

</div>
</ng-template>